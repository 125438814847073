$primary: #1c1c1c;
$info: #ffd109;
// --bs-dropdown-bg: var(white);
$accordion-button-active-bg: transparent;
$accordion-button-active-color: black;
$accordion-button-color: black;
$accordion-button-bg: transparent;
$accordion-bg: transparent;
$accordion-border-width: 0px;
$accordion-border-color: none;
$accordion-border-radius: 0px;
// $accordion-button-padding-x:100px;
// $accordion-body-padding-x:100px;

@import "~bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;500;600&display=swap");

@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./assets/Sharp_Grotesk/SharpGrotesk-Book25.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
  color: #4d4d4d;
}

@font-face {
  font-family: 'Sharp Grotesk Header';
  src: url('./assets/Sharp_Grotesk/SharpGrotesk-Medium25.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
  color: #4d4d4d;
}

@font-face {
  font-family: 'Sharp Grotesk Book';
  src: url('./assets/Sharp_Grotesk/SharpGrotesk-Book25.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
  color: #4d4d4d;
}

@font-face {
  font-family: 'Sharp Grotesk Light';
  src: url('./assets/Sharp_Grotesk/SharpGrotesk-Light25.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
  font-display: swap;
  color: #4d4d4d;
}
@font-face {
  font-family: 'Product Sans';
  src: url('./assets/product-sans/Product-Sans-Regular.ttf') format('opentype');
  font-display: swap;
font-weight: lighter;
color: #3b3b3b;

}

html,
body {
  font-family: 'Product Sans';
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;

}

.home {
  padding-right: 250 !important;
  margin: 0 !important;
  width: 100% !important;
}

// this is for swiper carousel 

// .swiper {
//   width: 100%;
//   height: 450px;
// }


// .swiper .why-borga {
//   width: 100%;
//   height: 850px;
// }


// .swiper-slide {
//   text-align: center;
//   font-size: 18px;
//   background: #fff;

//   /* Center slide text vertically */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding:30px 0px;
// }

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;

// }

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  background-color: #ffd109;

}

.swiper-pagination .swiper-pagination-bullet {

  background-color: rgb(139, 138, 138);

}

.logo-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

// @media (max-width: 992px) {
//   .swiper {
//     width: 100%;
//     height:  auto;
//   }
// }

@media (max-width: 500px) {

  .logo-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: start;
    gap: 10px;
  }
}

// .navbar {
//   // border-bottom: 1px solid #838383;
//   margin: 0% 5%;
// }

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
  height: 2px;
  width: 5px;
}

// .navbar-nav .nav-link {
//   color: var(--bs-navbar-active-color);
//   font-weight: 100;
// }

// .navbar-nav .nav-link.active,
// .navbar-nav .nav-link.show {
//   color: var(--bs-navbar-active-color);
//   text-decoration: underline;
//   font-weight: 100;
//   margin-left: 50% !important;
//   padding-left: 50% !important;
// }

// .navbar-nav .nav-link:hover {
//   color: $info;
//   font-weight: 500;
// }

// .nav-border {
//   border-bottom: 1px solid #838383;
// }

.custom-toggler .navbar-toggler-icon {
  background-color: #303030;
  border-radius: 5px;
  margin-left: 50% !important;
  padding-right: 50% !important;
}

// .nav-btn,
// .nav-btn:hover,
// .nav-btn:visited {
//   font-size: 12px;
//   color: #fff !important;
//   padding: 20px;
//   font-weight: 100;
//   padding-left:50% !important;
//   border-radius: 30px !important;
// }

a {
  color: $info;
  text-decoration: none;
}

// ####################### sections ##################################
.easy-pay-section {
  width: 100%;
}

.why-borga-section {
  width: 100%;
}

.what-is-borga-section {
  width: 100%;
  height: 600px !important;
}

.what-people-say-about-us-section {
  width: 100%;
}

.tutorials-section {
  width: 100%;
  margin-top: 10%;
}

.alternating-image-section {
  width: 100% important;
}

.on-screen-type-image-display {
  display: none;
  height: 500px;
}

.section-text {
  font-size: 28px;
  font-family: "Sharp Grotesk", sans-serif;
  font-weight: bolder;
  margin-top: 40px;
}

.section-text-header{
  font-family: "Sharp Grotesk Header", sans-serif;
  font-size:30px;
}


.alternating-image-section {
  background-image: url("./assets/images/alternative-background/bg1.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 560px;
  height: 400px;
  transition: transform 0.3s ease;
  border: none;

  .alternating-image-section-text {
    font-size: vw;
    margin-top: -1%;
  }

  // .alternating-image-section-col {
  //   margin-top: 3%;
  // }
}

.alternating-image-section-container {
  // padding-left: 5%;
}
.why-borga-card-section{
margin-top: 150px;
}

.alternating-image-section:hover {
  background-image: url("./assets/images/alternative-background/bg2.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  width: 560px;
  height: 400px;
  background-position: top;
}

.google-play-logo {
  background-image: url("./assets/images/brand-logo/googleplay.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 60px;
  border-radius: 10px;
  margin-top: -2%;
}

.app-store-logo {
  background-image: url("./assets/images/brand-logo/appstore.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 60px;
  border-radius: 10px;
  margin-top: -2%;
  margin-left: 10px;
}

.brand-logos {
  display: flex;
  justify-content: left;
}


.logo-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.why-borga-card-section{
  margin-top: 20%;
  }

@media only screen and (max-width: 2292px) {
  .phone-container {
    margin-left: 8% !important;
  }

  .style-for-phone-image {
    width: 300px !important;
    margin-top: -50%;
    height: 600px !important;
    transition: transform 0.5 ease-in;
  }
}

@media only screen and (max-width: 1681px) {
  .phone-container {
    margin-left: 8% !important;
  }

  .style-for-phone-image {
    width: 17vw;
    margin-top: -50%;
    height: 60vh;
    transition: transform 0.5 ease-in;
  }
}

@media only screen and (max-width: 1350px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 70vh;
    // background:black;
  }

  .style-for-phone-image {
    width: 270px !important;
    margin-top: 50% !important;
    height: 65vh !important;
    transition: transform 0.5 ease-in;
  }

  .phone-container {
    margin-left: 20% !important;
  }

}

@media only screen and (max-width: 1570px) {
  .mobile {
    display: none;
    position: absolute;
    margin-top: -8% !important;
    transition: transform 0.3s ease;
  }

}

@media only screen and (max-width: 1470px) {
  .mobile {
    display: none;
    position: absolute;
    margin-top: -9% !important;
    transition: transform 0.3s ease;
  }

}

@media only screen and (max-width: 1249px) {
  .what-is-borga-section {
    height: 70vh !important;
    // background:black;
  }

  .mobile {
    position: absolute;
    margin-top: -9%;
    transition: transform 0.3s ease;


    .phone-1 {
      width: 410px;
      transition: transform 0.3s ease;
      margin-left: -60px;

    }

    .phone-2 {
      width: 270px;
      margin-left: -80px;
      transition: transform 0.3s ease;
    }
  }

  .mobile:hover {
    .phone-1 {
      transform: translateY(-50px);

    }

    .phone-2 {
      transform: translateY(50px);
    }

  }
}

@media only screen and (max-width: 1218px) {
  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }
  .why-borga-card-section{
    margin-top: 15%;
    }

  .what-is-borga-col {
    width: 100vw !important;
  }

  .mobile {
    display: none;
    position: absolute;
    margin-top: -9%;
    transition: transform 0.3s ease;


    .phone-1 {
      width: 410px;
      transition: transform 0.3s ease;
      margin-left: -60px;
      display: none;

    }

    .phone-2 {
      width: 270px;
      margin-left: -80px;
      transition: transform 0.3s ease;
      display: none;
    }
  }

  .mobile:hover {
    .phone-1 {
      transform: translateY(-50px);

    }

    .phone-2 {
      transform: translateY(50px);
    }

  }
}

@media only screen and (min-width: 1200px) {
  .why-borga-row-container {
    display: flex;
    overflow: auto;
    /* Set overflow to auto to allow scrolling */
    white-space: nowrap;
    /* Prevent wrapping of cards to enable horizontal scrolling */
    flex-wrap: nowrap;
    width: 100%;
  }

  // .why-borga-card {
  //   flex: 0 0 auto;
  // }
}

@media only screen and (max-width: 1199px) {
  .why-borga-row-container {
    display: flex;
    overflow: auto;
    /* Set overflow to auto to allow scrolling */
    white-space: nowrap;
    /* Prevent wrapping of cards to enable horizontal scrolling */
    flex-wrap: nowrap;
    width: 100%;
    // padding: 0 15px; /* Use flex display for smaller screens */
  }

  .why-borga-col-container {
    width: 400px !important;
  }

  .why-borga-card {
    flex: 0 0 auto;
    overflow: hidden;
  }

  .why-borga-body-text {
    width: auto !important;
    //  font-size:10px;
  }

  .alternating-image-section-text {
    font-size: 40px !important;
    // line-height: 100px;
    justify-content: center;
    text-align: center;
  }

  .alternating-image-section-col {
    .sub-text {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center !important;

    }
  }

  .brand-logo-container {
    display: flex;
    // justify-content: center !important;
    // margin-top:-30px;
  }
}

@media only screen and (max-width: 1790px) {
  .alternating-image-section-col {
    margin-top: 15% !important;
  }

  // .alternating-image-section-row {
  //   margin-top:-15%;
  // }
  .brand-logos {
    margin-top: -10% !important;
  }

  .sub-text {
    width: 400px;
  }

  .alternating-image-section-text {
    font-size: 35px !important;
    margin-top: -1%;
  }
}

@media only screen and (max-width: 1278px) {
  .feedback-card-col {
    width: 400px !important;
  }

  .what-is-borga-section {
    height: 70vh;
    // background:black;
  }

  .alternating-image-section-col {
    margin-top: 0% !important;
  }

  .style-for-phone-image {
    width: 250px !important;
    margin-top: 50% !important;
    height: 85vh !important;
    transition: transform 0.5 ease-in;
  }

  .phone-container {
    margin-left: 20% !important;
  }
}

@media only screen and (max-width: 1199px) {
  .tut-header {
    font-size: 40px !important;
    line-height: 30px !important;
  }

  .tut-nav .tut-nav-text {
    font-size: 13px ;
  }

  .what-is-borga-section {
    height: 160vh;
    // background:black;
  }

  .why-borga-card-section{
    margin-top: 10% !important;
    }

  .style-for-phone-image {
    width: 230px !important;
    margin-top: 50% !important;
    height: 75vh !important;
    transition: transform 0.5 ease-in;
  }

  .phone-container {
    margin-left: 20% !important;
  }
}

@media only screen and (max-width: 1174px) {
  .alternating-image-section-col {
    margin-top: 0% !important;
  }

  .brand-logos {
    margin-top: -15% !important;
  }

  .sub-text {
    width: 400px;
  }

  .alternating-image-section-text {
    font-size: 35px !important;
    margin-top: -1%;
  }

  .what-is-borga-section {
    height: 160vh;
    // background:black;
  }

  .style-for-phone-image {
    width: 230px !important;
    margin-top: 50% !important;
    height: 75vh !important;
    transition: transform 0.5 ease-in;
  }

  .phone-container {
    margin-left: 20% !important;
  }
}

.navbar-text{
  font-family: 'Sharp Grotesk';
  font-size:12px !important;
}

@media only screen and (max-width: 1162px) {
  // .alternating-image-section {
  //   height: 550px;
  //   margin-top: 5%;
  //   margin-bottom: 30%;
  //   background-size: contain !important;
  // }

  // .alternating-image-section:hover {
  //   background: none;
  // }
  .alternating-image-section-text {
    font-size: 40px !important;
    // line-height: 100px;
    justify-content: center;
    text-align: center;
  }

  .alternating-image-section {
    margin-bottom: -15% !important;
  }

  .alternating-image-section-col {
    justify-content: center;
    text-align: center;
    margin-top: 0% !important;
  }

  .navbar-text{
    font-family: 'Sharp Grotesk';
    font-size:12px !important;
  }

  .dropdown-content {
    width: 250px; /* Adjust as needed */
    position: absolute;
    top: 10%; /* Position it right below the navbar */
    right: 0; /* Align it to the right side of the page */
    z-index: 1000; /* Ensure it overlaps the navbar */
    background-color: white; /* Change as needed */
    border: none; 
    border-radius: 10px;
   
    /* Optional styling */
  }

  .google-play-logo {
    background-image: url("./assets/images/brand-logo/googleplay.png");
    // background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    // width:50%;
    height: 5vh;
    border-radius: 10px;
    margin-top: 18%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .app-store-logo {
    background-image: url("./assets/images/brand-logo/appstore.png");
    // background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    // width:50%;
    height: 5vh;
    border-radius: 10px;
    margin-top: 18%;
    margin-left: 0px !important;
  }

  .brand-logos {
    display: flex;
    justify-content: center !important;
    // margin-top:-2%;
  }

  .sub-text {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center !important;

  }

  .what-is-borga-section {
    height: 150vh;
    // background:black;
  }

  .style-for-phone-image {
    width: 230px !important;
    margin-top: 50% !important;
    height: 75vh !important;
    transition: transform 0.5 ease-in;
  }

  .phone-container {
    margin-left: 20% !important;
  }

  .brand-logo-container {
    display: flex;
    // justify-content: center !important;
  }
}

.tut-nav .tut-nav-text {
  font-size: 13px !important;
}

@media only screen and (max-width: 991px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 50 !important;
    // background:black;
  }

  .why-borga-card-section{
    margin-top: 15% !important;
    }


  .logo-section {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // white-space: nowrap;
  }

  .style-for-phone-image {
    width: 230px !important;
    margin-top: 130% !important;
    height: 75vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 10% !important;
    display: none;
  }

  .what-is-borga-header {
    font-size: 40px !important;
    // padding-left: 8%;
    // padding-right: 8%;
  }

  .tut-header {
    font-size: 50px !important;
    line-height: 60px !important;
    text-align: left;
  }

  .tut-nav-container {
    //  display:flex !important;
    width: 80% !important;


  }


  .arrow {
    padding-top: 5% !important;
  }

  .nav-col {
    padding: 0 !important;
  }

  .tut-nav-arrow-sector {
    .tut-nav-container {
      justify-content: start;
    }
  }

  .tut-nav-icon {
    width: 45px !important;
    height: 45px !important;
  }

  .tut-nav .tut-nav-text {
    font-size: 13px !important;
  }

  .rates-dropdown-item {
    position: relative;
  }

  .dropdown-menu {
    background: white !important;
    width: auto !important;
    border-radius: 20px !important;
    max-width: none !important;
    transform: translateX(-50%);
    left: 50%;
    margin-left: 0 !important;
  
  }

  .country-rep-text {
    font-size: 10px;
    color: #1c1c1c;
  }

  .currency {
    font-size: 12px;
    font-weight: bold;
    color: #1c1c1c;
  }

  .drop-down-currency {
    // width: 400px;
    max-width: 100%;
    left: 0 !important;
    /* Set the maximum width to 100% of the container */
    right: 0 !important;
    padding: 10px;
    background-color: white;
    z-index: 2;
    /* Ensure it's above other content */
  }

  .footer-col {
    display: flex !important;
    justify-content: center !important;
  }

  .footer-menu {
    display: flex;
    justify-content: space-evenly;
  }


}

@media only screen and (max-width: 930px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 200px !important;
    margin-top: 180% !important;
    height: 70vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 15% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 870px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 200px !important;
    margin-top: 180% !important;
    height: 70vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 15% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 912px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 300px !important;
    margin-top: 150% !important;
    height: 70% !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 5% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 820px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 40vh !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 300px !important;
    margin-top: 150% !important;
    height: 65% !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 5% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 784px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 230px !important;
    margin-top: 130% !important;
    height: 80vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 13% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 768px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 300px !important;
    margin-top: 100% !important;
    height: 70% !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: -2% !important;
    display: none;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 700px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 200px !important;
    margin-top: 50% !important;
    height: 70vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 10% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 20% !important;
  }

  .feedback-card-col {
    width: 550px !important;
  }
}

@media only screen and (max-width: 630px) {
  .custom-md-col {
    width: 100%;
  }

.why-borga-card-section{
  margin-top: 30px;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 180px !important;
    margin-top: 50% !important;
    height: 70vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 10% !important;
  }

  .feedback-card-body-main-text {
    margin-top: -30%;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }

  .tut-nav-container {
    text-align: start;
    padding-left: 10px;
  }

  .tut-header-container {
    padding-left: 10px;
  }
}

.why-borga-card-section{
  margin-top: 30px;
  }

@media only screen and (max-width: 540px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }
  
  .why-borga-card-section{
    margin-top: 70px;
    }

  .style-for-phone-image {
    width: 210px !important;
    margin-top: 50% !important;
    height: 70vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 10% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30%;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }

  .brand-logo {
    width: 150px !important;
  }

  .tut-nav-container {
    text-align: start;
    padding-left: 10px;
  }

  .faqs-header {
    font-size: 35px !important;
    text-align: start !important;
    width: 100% !important;
    padding: 20px 0px !important;
    // padding: 90px;
  }

  .accordion-section-header {
    text-align: start !important;
  }
}

@media only screen and (max-width: 520px) {
  .tut-header {
    font-size: 30px !important;
    line-height: 40px !important;
    padding-left: 10px;
    // text-align: center;
  }

  .tut-nav-container {
    text-align: start;
    padding-left: 20px !important;
  }

  .tut-nav-arrow-sector {
    .tut-nav-container {
      justify-content: center;
    }
  }

  .tut-nav-icon {
    width: 35px !important;
    height: 35px !important;

    // padding:20px;
    .arrow {
      font-size: 15px;
      margin-top: -40% !important;
    }
  }

  .tut-nav .tut-nav-text {
    font-size: 12px !important;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 170px !important;
    margin-top: 60% !important;
    height: 60vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 9% !important;
    display: none;
  }

  .tut-header-container {
    width: 80% !important;
  }

  .feedback-cards-body {
    // background: #303030;
    height: auto !important;
    padding: 30px;
    color: white;
    // border-radius: 20px;
  }

}

@media only screen and (max-width: 479px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }

  .style-for-phone-image {
    width: 170px !important;
    margin-top: 60% !important;
    height: 60vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 4% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }

  .tut-header-container {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 412px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: auto !important;
    // background:black;
  }



  .style-for-phone-image {
    width: 160px !important;
    margin-top: 50% !important;
    height: 100% !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 5% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }
}

@media only screen and (max-width: 400px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 100%;
    // background:black;
  }

  .style-for-phone-image {
    width: 160px !important;
    margin-top: 50% !important;
    height: 60vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 2% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }
}

@media only screen and (max-width: 380px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 100%;
    // background:black;
  }

  .style-for-phone-image {
    width: 130px !important;
    margin-top: 50% !important;
    height: 50vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 2% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }
}

@media only screen and (min-width:765px) {
  .whyBorgaCard {
    height: 500px !important;
  }
}


@media only screen and (max-width: 320px) {
  .custom-md-col {
    width: 100%;
  }

  .what-is-borga-section {
    height: 100%;
    // background:black;
  }

  .style-for-phone-image {
    width: 110px !important;
    margin-top: 70% !important;
    height: 40vh !important;
    transition: transform 0.5 ease-in;
    display: none;
  }

  .phone-container {
    margin-left: 5% !important;
    display: none;
  }

  .feedback-card-body-main-text {
    margin-top: -30% !important;
  }

  .what-people-say-about-us-section-header {
    padding-left: 8% !important;
    padding-top: 25% !important;
  }
}

.why-borga-col-container {
  width: 400px !important;
  position: relative;
  overflow: hidden;
  // height: 300px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: transform 0.3s ease;
}

.why-borga-row-container {
  overflow-y: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 15px;
}

.why-borga-body-text {
  width: auto;
  overflow: hidden;
  white-space: normal;
  font-size: 13px;
}

.why-borga-text {
  margin-top: 8%;

  .why-borga-row-container {
    // justify-content: center;
  }

  .why-borga-text-title {
    padding-left: 18%;
  }
}

.content-text{
  font-size:16px
}

.why-borga-card {
  position: relative;
  overflow: hidden;
  height: 460px;

  .why-borga-card-images {
    height: 260px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    translate: transform 1.9s ease;
    object-fit: cover;
    object-position: top;
    transition: transform 0.5s ease, border-top-left-radius 0.5s ease, border-top-right-radius 0.5s ease;
  }

  .icon-bg-container {
    width: 50px;
    height: 50px;
    position: absolute;
    margin-top: -10%;

    .icon-display {
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .card-body {
    height: 225px;
    position: absolute;
    width: 100%;
    // margin-top: -15%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    // margin-bottom: 5%;
    bottom: 0;
  }
}
.tips-header{
  font-family: 'Sharp Grotesk Book';
  font-size: 15px;
}

.point-header{
  font-family: 'Sharp Grotesk header';
  font-size: 15px !important;
}
.point-descrp{
  font-family: 'Sharp Grotesk Light';
  font-size: 16px !important;
}

.card-header-why-borga{
  font-family: 'Sharp Grotesk';
  font-size: 16px;
}
.why-borga-card:hover .why-borga-card-images {
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  transform: scale(1.2);
  transition-delay: 0.3s;
}

.why-borga-card:hover .card-body {
  background-color: #ffffff;
}

.what-is-borga-section {
  margin-top: 5%;
  padding-bottom: 5%;

  .what-is-borga-header {
    font-size: 70px;
    // padding-left: 8%;
    // padding-right: 8%;
  }

  .what-is-borga-body {
    margin-top: 3%;
    background: rgb(241, 241, 241);
    background: linear-gradient(90deg,
        rgba(241, 241, 241, 1) 41%,
        rgba(255, 255, 255, 1) 70%);
  }

  .what-is-borga-row-bg {
    padding: 5% 0%;

    .what-is-borga-col {
      // padding-left: -10%;
    }
  }
}

.phone-container {
  margin-left: 5%;
}

.style-for-phone-image {
  width: 17vw;
  margin-top: -50%;
  height: 65vh;
  transition: transform 0.5 ease-in;
}

.phone-container:hover {
  .first-phone {
    margin-top: -12%;
  }

  .second-phone {
    margin-top: 12%;
  }
}

.what-people-say-about-us-section {
  background: #1c1c1c;
  height: auto;
  width: 100%;
  margin-top: 10%;

  .what-people-say-about-us-section-header {
    padding-left: 8%;
    padding-top: 5%;
  }
}

.feedback-cards-scroll-container {
  overflow-y: hidden;
  overflow-x: hidden;
}

.feedback-card-row-container {
  width: 100%;
  padding-left: 8%;
  margin-top: 3%;
  background: transparent;
  border: none !important;
  overflow: scroll;
  overflow-y: hidden;

  .feedback-cards {
    height: auto;
    background: #303030;
    color: white;
    border-radius: 20px !important;
  }

  .feedback-cards-body {
    // background: #303030;
    height: 350px;
    color: white;
    // border-radius: 20px;
  }

  .feedback-card-body-main-text {
    // margin-top: 100px;
  }

  .border-divider {
    border-bottom: 1px solid rgba(128, 128, 128, 0.363);
  }

  .feedback-card-image-container {
    width: 50px;
    height: 50px;
    background: #f1f1f1;
    border-radius: 50%;
    border: none;

    .feedback-card-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
  }
}

.custom-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tut-section {
  margin-top: 5%;
  margin-bottom: 5%;

  .tut-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    /* Adjust this value as needed to set the spacing between columns */
  }

  .tut-header {
    width: auto;
    font-size: 60px;
    line-height: 70px;
    margin-right: -5% !important;
  }

  .tut-card {
    background: #e3e3e3;
    height: 100%;
    transition: transform 0.3s ease, background-color 0.5s ease;

    .tut-card-body {
      .tut-card-num {
        font-size: 60px;
        padding: 0px;
      }

      .tut-card-title {
        font-size: 16px;
        font-weight: bolder;
        padding-bottom: 5%;
        font-family: 'Sharp Grotesk header';
      }

      .tut-card-body {
        font-size: 17px;
        padding-bottom: 5%;
      }
    }
  }

  .tut-nav-container {
    // margin-right: -10%;
  }
}
.merchant-session-header{
  font-family: 'Sharp Grotesk';
  font-size: 30px;
}
.merchant-carousel-tab{
  font-family: 'Sharp Grotesk';
  font-size: 17px;
}

.tut-nav-container {
font-family: 'Sharp Grotesk';
}

.tut-header{
  font-family: 'Sharp Grotesk';
  font-size: 30px !important;
}

.tut-card:hover {
  background: #ffd109;
  transform: translateY(-30px);
}

.quotation-mark {
  font-size: 170px;
}

.tut-nav-icon {
  width: 50px;
  height: 50px;
  // padding:20px;
}

.nav-link-with-details {
  position: relative;
}

#nav-dropdown {
  color: #1c1c1c;
  background: white;
}

// .dropdown-menu {
//   background:white !important;
//   width:350px !important;
//   margin-left:-330% !important;
//   border-radius: 20px !important;
//   // width:500px !important;
// }

.country-rep-text {
  font-size: 10px;
  color: #1c1c1c;
}

.currency {
  font-size: 12px;
  font-weight: bold;
  color: #1c1c1c;
}

// .rates-details{
//   margin-bottom:-40%;
//   width:300px !important;
// }
// .rates-dropdown {
//   display: none;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   background-color: white;
//   border: 1px solid #ccc;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   padding: 10px;
//   z-index: 1; /* Adjust as needed */
// }

// .rates-dropdown.show {
//   display: block;
// }

.rates-wrapper {
  position: relative;
}

.rates-details {
  display: none;
}

.rates-details.active {
  display: block;
  width: 400px;

  /* Add your custom styles for the cards here */
}

// .drop-down-currency{
//   // margin-left:38%;
//   width:400px;
// }

.offcanvas-content-wrapper {
  height: 100%;
}

.drop-down-currency {
  width: 400px;
  max-width: 100%;
  left: 70%;
  /* Set the maximum width to 100% of the container */
  right: 20%;
  padding: 10px;
  background-color: white;
  z-index: 2;
  /* Ensure it's above other content */
}

.footer-icons-container {
  float: right;

  .border-for-icons {
    border: 1px solid rgba(128, 128, 128, 0.603);
  }
}

.footer-text {
  font-size: 15px;
}

.footer-logo-section {
  border-bottom: 1px solid rgba(128, 128, 128, 0.74);
}

.privacy-section-header {
  font-size: 50px;
}

.privacy-section-container {
  max-height: auto;
  padding: 20px;
  text-align: justify;
}

.faqs-icon-container {
  border: 1px solid rgba(128, 128, 128, 0.247);
  width: 50px;
  text-align: center;
}

.faqs-card-body {
  font-size: 12px;
}

.faqs-header {
  font-size: 60px;
  padding: 90px;
}

.faqs-cards {
  height: 300px;
}

.accordion-section {
  margin-top: 10%;
}

.accordion-section-header {
  font-size: 40px;
}

.terms-conditions-section {
  text-align: justify;
  max-height: auto;
  margin-bottom: 10%;

}


.mobile {
  position: absolute;
  margin-top: -7%;
  transition: transform 0.3s ease;


  .phone-1 {
    width: 410px;
    transition: transform 0.3s ease;
    margin-left: 80px;

  }

  .phone-2 {
    width: 270px;
    margin-left: -80px;
    transition: transform 0.3s ease;
  }
}

.mobile:hover {
  .phone-1 {
    transform: translateY(-50px);

  }

  .phone-2 {
    transform: translateY(50px);
  }

}

.arrow-btn:hover .tut-nav-icon {
  background: #ffd109;
  border: none !important;
}

.payment-options {
  background: white;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  justify-content: end;
  justify-items: end;
  align-items: center;
  width: 100px;
  padding: 0px 5px;
  justify-self: end;
}

@media only screen and (max-width: 500px) {
  .email-verification-card {
    width: 400px !important
  }
}



// for fixing docker issue concerning container 
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  .section-text-header{
    font-family: "Sharp Grotesk Header", sans-serif;
    font-size:26px;
  } 
}


@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}


@media only screen and (max-width: 430px) {

  .section-text {
    font-size: 18px;
    font-family: "Sharp Grotesk", sans-serif;
    font-weight: bolder;
    margin-top: 40px;
  }
  .section-text-header {
    font-size: 18px;
    font-family: "Sharp Grotesk header", sans-serif;
    font-weight: bolder;
    margin-top: 20px;
  }
  .content-text {
    font-family: 'Product Sans';
   font-size:14px;
  
  }

}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}


.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  display: none;


}

.custom-carousel .carousel-indicators {
  display: none;
}